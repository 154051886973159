import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { Index } from 'elasticlunr'

import { useChecklistQuery } from '../hooks/useChecklistQuery'

import { getChecklistResults } from '../lib/search-helpers'

import SEO from '../components/seo'
import Layout from '../components/layout'
import Search from '../components/search'

export const query = graphql`
  query SearchIndexQuery {
    siteSearchIndex {
      index
    }
  }
`

const SearchPage = props => {
  const title = 'Search'

  const [results, setResults] = useState([])

  const checklistData = useChecklistQuery()

  // We are using url param as source of searched string
  const searchQuery = props.location
    ? new URLSearchParams(props.location.search).get('keywords')
    : ''
  let index = Index.load(props.data.siteSearchIndex.index)

  // Run only when 'props.location.search' change
  useEffect(() => {
    if (searchQuery) {
      const posts = index
        .search(searchQuery, {
          expand: true,
          bool: 'AND'
        })
        // Map over each ID and return the full document
        .map(({ ref }) => index.documentStore.getDoc(ref))

      // Merge results from ElasticLunr search with custom checklist search results
      const mergedPosts = posts.concat(
        getChecklistResults(checklistData.contentSections, searchQuery)
      )

      setResults(mergedPosts)
    }

    if (!searchQuery) setResults([])
  }, [props.location.search])

  return (
    <Layout title={title} {...props}>
      <SEO title={title} />
      <Search results={results} searchQuery={searchQuery} />
    </Layout>
  )
}

export default SearchPage
