import React from 'react'
import { format } from 'date-fns'

import {
  groupByObjectProperty,
  getDateRangeArr,
  sortArrayByTitle,
  formatStringByNumber
} from '../lib/helpers'

import SearchResultLink from '../mui-components/SearchResultLink/SearchResultLink'
import NonExpansiblePanel from '../mui-components/NonExpansiblePanel/NonExpansiblePanel'

// Search component
const Search = props => {
  // First group results by different types
  const groupedResults = groupByObjectProperty(props.results, 'type')

  // Prepare static header
  const content = [
    <NonExpansiblePanel
      title={
        props.searchQuery ? 'Search results for: "' + props.searchQuery + '"' : 'No results found'
      }
      key="search-title"
    />
  ]

  // Prepare results boxes
  Object.keys(groupedResults).map((groupProp, index) => {
    let title = ''

    // Get proper title
    switch (groupProp) {
      case 'sanction-type':
        title = 'Sanction types'
        break
      case 'cases':
        title = 'Cases'
        break
      case 'episode':
        title = 'Episodes'
        break
      case 'checklist':
        title = 'Design'
        break
      default:
        title = 'Other'
        break
    }

    const searchBoxChildren = []

    // Sort results by title
    const sortedResults = sortArrayByTitle(groupedResults[groupProp], 'title')

    // Get all links for the type
    sortedResults.forEach((item, index) => {
      let itemDates = null
      const itemTitle =
        item.type === 'cases' ? (item.references[0] ? item.references[0] : item.title) : item.title

      if (item.type === 'cases' || item.type === 'episode') {
        const startDate = item.date[0] ? format(new Date(item.date[0]), 'MMM-yyyy') : null
        const endDate = item.date[1] ? format(new Date(item.date[1]), 'MMM-yyyy') : null
        itemDates = getDateRangeArr(startDate, endDate)
      }

      searchBoxChildren.push(
        <SearchResultLink to={item.path} title={itemTitle} subtitle={itemDates} key={index} />
      )
    })

    // Create Search panel with links
    content.push(
      <NonExpansiblePanel
        title={title}
        subtitle={
          groupedResults[groupProp].length +
          ' ' +
          formatStringByNumber('result', groupedResults[groupProp].length)
        }
        highlight
        key={groupProp}
      >
        {searchBoxChildren}
      </NonExpansiblePanel>
    )
  })

  return content
}

export default Search
