import { removeArrayDuplicates } from './helpers'

export function getChecklistResults(sections, searchedString) {
  if (sections === undefined || sections.length === 0) {
    return []
  }

  // Process checklist sections so we can search in them
  // 1. Get all checklist sections and recursively their hierarchicalContent sections
  // 2. Set some additional parent properties
  // 3. Prepare content containing raw strings from various sections which would act like an "search index" and we can search in it
  const allSections = []
  const getAllNestedSections = (obj, parent) => {
    Object.keys(obj).forEach(key => {
      const slug = obj[key].slug ? obj[key].slug.current : ''

      // We need to have new unique property we can group items by (parent ID in this case)
      const customNode = { ...obj[key] }

      // Set proper nested slug for hierarchy children
      if (parent && parent.nestedSlug) {
        customNode.nestedSlug = parent.nestedSlug + '/' + slug
      } else {
        customNode.nestedSlug = slug
      }

      // customNode.parentID = parent ? parent._key : 0
      customNode.parentTitle =
        parent && parent.sectionSettings ? parent.sectionSettings.sectionTitle : 'Checklist'

      // Special case, when we want to get hierarchical section title, not it's parent title
      if (customNode._type === 'hierarchicalSection' && customNode.sectionSettings) {
        customNode.parentTitle = customNode.sectionSettings.sectionTitle
      }

      customNode.indexedContent = ''

      // Index section titles
      if (customNode.sectionSettings) {
        customNode.indexedContent += ' ' + customNode.sectionSettings.sectionTitle
      }

      // Index Content sections text (it is wrapped in 'block' object, so we need to do multiple loops)
      // E.g. "Summary section"
      if (customNode._type === 'contentSection' && customNode._rawSectionContent) {
        customNode._rawSectionContent.forEach(contentItem => {
          contentItem.children
            ? contentItem.children.forEach(child => {
                customNode.indexedContent += ' ' + child.text
              })
            : null
        })
      }

      // Index Resolutions titles
      if (
        customNode._type === 'multiContentSection' &&
        customNode.resolutionSections &&
        customNode.resolutionSections !== undefined
      ) {
        customNode.resolutionSections.forEach(resItem => {
          resItem.resolutionSections.forEach(resSec => {
            customNode.indexedContent += ' ' + resSec.resolutionName
          })
        })
      }

      // Index Resolutions titles
      if (
        customNode._type === 'selectedResolutionsSection' &&
        customNode.resolutions &&
        customNode.resolutions !== undefined
      ) {
        customNode.resolutions.forEach(resItem => {
          customNode.indexedContent += ' ' + resItem.resolutionName
          // Index also name with trimmed spaces
          customNode.indexedContent += ' ' + resItem.resolutionName.split(' ').join('')
        })
      }

      // We don't need all sections in our array
      if (
        customNode._type === 'contentSection' ||
        customNode._type === 'multiContentSection' ||
        customNode._type === 'selectedResolutionsSection' ||
        customNode._type === 'hierarchicalSection'
      ) {
        allSections.push(customNode)
      }

      if (typeof customNode.sectionContent === 'object') {
        getAllNestedSections(customNode.sectionContent, customNode)
      }
    })
  }
  getAllNestedSections(sections)

  // Now loop through the sections and check if our index contains searched string
  const results = []

  allSections.forEach(node => {
    if (
      node.indexedContent &&
      node.indexedContent.toLowerCase().indexOf(searchedString.toLowerCase()) !== -1
    ) {
      let slug = node.nestedSlug ? node.nestedSlug : ''
      // Remove trailing slashes which are causing duplicated results
      slug = slug.replace(/\/$/, '')

      results.push({
        path: '/checklist/' + slug,
        title: node.parentTitle ? node.parentTitle : '',
        type: 'checklist'
      })
    }
  })

  // Remove duplicates with the same path
  const finalResults = removeArrayDuplicates(results, 'path')

  return finalResults
}
